import React, { Component } from 'react';
import './App.css';
import Axios from 'axios';
import Trainselect from './components/Trainselect';

import 'bootstrap/dist/css/bootstrap.min.css';
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'


export default class App extends Component {
  state = {
    trains: [],
    chosen: {},
    spinner: []
  }


  /**
   * Helper function that retrieves data from the db api with given endpoint
   */
  getData = url => {
    return new Promise(resolve => {
      var xmlDoc;
      var config = {
        baseURL: "https://api.deutschebahn.com/timetables/v1",
        headers: {
          Authorization: "Bearer b4c46ae338c54c66189b6513a70113f7"
        }
      };
      Axios.get(url, config)
        .then(res => {
          var parser = new DOMParser();
          xmlDoc = parser.parseFromString(res.data,"text/xml");
          resolve(xmlDoc);
        });
    });
  }

  /**
   * Helper function, that takes a YYMMDDHHMM String and returns a proper Date Object
   */
  getDate = str => {
    var newtime = new Date();
    newtime.setSeconds(0,0);
    var hours = str.substring(6,8);
    hours = hours[0]===0 ? hours[1] : hours;
    newtime.setHours(hours);
    var minutes = str.substring(8,10);
    minutes = minutes[0]===0 ? minutes[1] : minutes;
    newtime.setMinutes(minutes);
    return newtime;
  }
  
  /**
   * Helper function that takes XML <s> object and returns the TimeString of it
   */
  getTimeString = el => {
    var str = "";
    for (var i=0; i<el.children.length; i++) {
      var child = el.children[i];
      if (child.nodeName === "dp" && child.attributes.ct !== undefined) {
        str = child.attributes.ct.nodeValue;
      }
    }
    return str;
  }

  getDestination = el => {
    var dest = "";
    for (var i=0; i<el.children.length; i++) {
      var child = el.children[i];
      if (child.nodeName === "dp") {
        dest = child.attributes.ppth.nodeValue;
      }
    }
    var n = dest.lastIndexOf('|');
    return dest.substring(n+1);
  }

  updateData = async () => {
    var dateF = require('dateformat');
    var trains = [];
    var date = new Date();
    
    //if we're already 10 min past the departure of re5, we probably want to look at the next one
    if (date.getMinutes()>28) {
      date.setTime(date.getTime() + 1000*60*60);
    }

    //add next 3 trains
    while (trains.length <= 2) {
      var d1 = dateF(date, "yymmdd'/'HH");

      var t = await this.getData('/plan/8000206/'+d1);
      t = t.getElementsByTagName('s');
      var el = {re:{},ic:{}};
      var re = {id:{},plannedDeparture:{},pDString:{},zugTyp:{},delay:{},destination:{}};
      var ic = {id:{},plannedDeparture:{},pDString:{},zugTyp:{},delay:{},destination:{}};

      for (var i = 0; i < t.length; i++) {
        var zugtyp = t[i].firstChild.attributes.c.nodeValue;
        var linie;
        
        try {
          linie = t[i].children[1].attributes.l.nodeValue;
        } catch (e) {
          linie = t[i].firstChild.attributes.n.nodeValue;
        }

        if (zugtyp === "RE" && linie === "5" && t[i].children[1].nodeName === "dp") {
          // make sure it's the departing one and grab id (=x)
          const abf = t[i].children[1].attributes.pt.nodeValue;
          re.id = t[i].attributes.id.nodeValue; re.plannedDeparture = this.getDate(abf); re.pDString = abf; re.zugTyp = "RE 5"; re.destination = this.getDestination(t[i]);
          el.re = re;
        }
        else if (zugtyp === "ICE" || zugtyp === "IC" || zugtyp === "EC") {
          try {
            var mabf = t[i].children[2].attributes.pt.nodeValue;
          } catch (e) {
            mabf = t[i].children[1].attributes.pt.nodeValue;
          }
          if (mabf.substring(8,10) === "13") {
            //found mallory, now the question is
            ic.id = t[i].attributes.id.nodeValue; ic.plannedDeparture = this.getDate(mabf); ic.pDString = mabf; ic.zugTyp = zugtyp+" "+linie; ic.destination = this.getDestination(t[i]);
            el.ic = ic;
          }
        }
      }
      // this is a pair of alice (re) and mallory (ic)
      if (el.re.id !== undefined) {
        trains.push(el);
      }

      date.setTime(date.getTime() + 1000*60*60);
    }
    //call changes timetable
    //check for delays of train with ID x

    var c = await this.getData('fchg/8000206');
    c = c.getElementsByTagName('s');

    trains.forEach( el => {
      let rNewTimeString = "";
      let iNewTimeString = "";
      for (i = 0; i < c.length; i++) {
        if (c[i].id === el.re.id) {
          rNewTimeString = this.getTimeString(c[i]);
          //rNewTimeString = c[i].firstElementChild.attributes.ct.nodeValue;
        }
        else if (c[i].id === el.ic.id) {
          //delay
          iNewTimeString = this.getTimeString(c[i]);
        }
      }
      if (rNewTimeString.length > 0 && rNewTimeString !== el.re.pDString) {
        //calculate delay
        var rnewTime = this.getDate(rNewTimeString);
        var rdelay = new Date(rnewTime - el.re.plannedDeparture)/60000;
        el.re.delay = rdelay;
      } else {
        el.re.delay = 0;
      }
      if (iNewTimeString.length > 0 && iNewTimeString !== el.ic.pDString) {
        //calculate delay
        var inewTime = this.getDate(iNewTimeString);
        var idelay = new Date(inewTime - el.ic.plannedDeparture)/60000;
        el.ic.delay = idelay;
      } else {
        el.ic.delay = 0;
      }

    });
    return new Promise(resolve => {resolve(trains)});
  }

  componentDidMount() {
    this.setState({spinner: "d-none"});
    this.refreshList();
  }

  refreshList () {
    this.setState({spinner: ""});
    this.updateData().then(trains => {
      this.setState({ trains: trains });
      this.setState({spinner: "d-none"});
    });
  }

  selectTrain = id => {
    this.state.trains.forEach(train => {
      if (train.re.id === id) {
        this.setState({chosen: train});
      }
    });
  }

  getChosen() {
    if (this.state.chosen.re === undefined) {
      return 0;
    } else {
      return this.state.chosen.re.id;
    }
  }

  render() {
    var chosen = "";
    var zid = "";
    var ic = "";
    var del;
    var tDel;
    var rDel;
    if (this.state.chosen.re === undefined) {
      chosen = <i>keiner</i>;
      var visible = 'd-none'
    } else {
      const c = this.state.chosen.re;
      const i = this.state.chosen.ic;

      visible = ''
      //id:{},lannedDeparture:{},pDString:{},zugTyp:{},delay:{}};
      chosen = c.zugTyp + " nach " + c.destination + ", Abfahrt um " + c.plannedDeparture.getHours() + ":" + c.plannedDeparture.getMinutes();
      zid = {re: c.id, ic: i.id};
      if (i.id !== undefined) {
        if (i.delay === 0) {
          del = <Badge variant="success">0 Minuten</Badge>;
        } else {
          del = <Badge variant="danger">{i.delay} Minuten</Badge>;
        }
        ic = i.zugTyp + " nach " + i.destination + ", Abfahrt um " + i.plannedDeparture.getHours() + ":" + i.plannedDeparture.getMinutes();
      } else {
        ic = <i>keiner</i>;
      }

      if (c.delay === 0) {
          rDel = <Badge variant="success">0 Minuten</Badge>;
      } else {
          rDel = <Badge variant="danger">{c.delay} Minuten</Badge>
      }

      if (i.id === undefined || c.delay > i.delay + 1) {
        tDel = c.delay;
      } else {
        if (i.delay > 2 && i.delay < 15) {
          tDel = Math.max(i.delay -2, c.delay);
        } else if (i.delay >= 15) {
          tDel = c.delay;
        } else {
          tDel = 0;
        }
      }

      if (tDel === 0) {
        tDel =  <Badge variant="success">0 Minuten</Badge>;
      } else if (tDel > 0 && tDel < 4) {
        tDel =  <Badge variant="warning">{tDel} Minuten</Badge>;
      } else if (tDel >= 4) {
        tDel =  <Badge variant="danger">{tDel} Minuten</Badge>;
      }
    }
    return (
      <div>
        <h1 id="header">Offizieller #dbakel Rechner <Badge variant="warning">Beta</Badge></h1>
        <div style={{padding:"0.5em"}}>
          <Container fluid="true">
            <Row id="row1">
              <Col xs={12} md={8} className="mb-3">
                <Alert variant="alex">
                  <Alert.Heading>Referenz wählen</Alert.Heading>
                  Welchen RE willst du nehmen?
                </Alert>
                <ListGroup>
                  <Trainselect trains={this.state.trains} selected={this.getChosen()} select={this.selectTrain}/>
                </ListGroup>
              </Col>
              <Col xs={12} md={4}>
                <Card bg="dark" text="white">
                  <Card.Header>Gewählter Zug</Card.Header>
                  <Card.Body>
                    <Card.Title>{chosen}</Card.Title>
                    <Card.Title className={visible}>Verspätung: {rDel}</Card.Title>
                    <Card.Text className={visible+" font-weight-light zid"}>ZugID: {zid.re}</Card.Text>
                    <Button variant="alex" className={visible+" w-100"} onClick={() => {this.setState({chosen: {}})}}>Löschen</Button>
                  </Card.Body>
                </Card>
                <Button variant="ivory" className="mt-3 w-100 mb-3" onClick={() => {this.refreshList()}}>Liste aktualisieren <Spinner animation="border" className={this.state.spinner} size="sm"></Spinner></Button>
              </Col>
            </Row>
          </Container>

          <div>
            <Card className={visible+" mb-3"} id="c1">
              <Card.Header id="ch1">Für deine Auswahl wird folgender Zug wichtig:</Card.Header>
              <Card.Body>
                <Card.Title className="mb-0">{ic}. Verspätung: {del}</Card.Title>
                <Card.Text className="font-weight-light zid">ZugID: {zid.ic}</Card.Text>
              </Card.Body>
            </Card>

            <Card bg="dark" text="white" className={visible}>
              <Card.Header>Voraussichtliche Verspätung RE</Card.Header>
              <Card.Body>
                <Card.Title><h1 className="text-center">{tDel}</h1></Card.Title>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

