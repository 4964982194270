import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Train from './Train'

export default class Trainselect extends Component {
    render() {
        return (
                this.props.trains.map(train => (
                
                    <Train key={train.re.id} train={train.re} selected={this.props.selected} select={this.props.select}/>
        
                ))
        );
    }
}

Trainselect.propTypes = {
    trains: PropTypes.array.isRequired
}
