import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ListGroup from 'react-bootstrap/ListGroup'

export default class Train extends Component {
    render() {
        var zugt = this.props.train.zugTyp;
        var dep = this.props.train.plannedDeparture.getHours()+":"+this.props.train.plannedDeparture.getMinutes();
        var selected = false;
        if (this.props.selected === this.props.train.id) {
            selected = true;
        } else {
            selected = false;
        }

        return (
            <ListGroup.Item action variant="dark" onClick={this.props.select.bind(this, this.props.train.id)} active={selected}>
                { zugt }, Abfahrt um {dep}
            </ListGroup.Item>
        )
    }
}

Train.propTypes = {
    train: PropTypes.object.isRequired
}
